import React from "react";
// Customizable Area Start
import ServiceTypeController, { configJSON } from "./ServiceTypeController";
import SearchIcon from "@material-ui/icons/Search";
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    InputAdornment,
    TextField,
    Typography,
    withStyles,
    FormControlLabel,
    FormGroup,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Chip,
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { styled } from "@material-ui/core/styles";
import CustomTab from "../../../../packages/components/src/design-system/ButtonTabs.web";
import AppBackground from "../../../../packages/components/src/design-system/BackgroundColor/AppBackground";
import CustomBox from "../../../../packages/components/src/design-system/Card/FilterCard";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
const deviceBasedDynamicDimension = MergeEngineUtilities.deviceBasedDynamicDimension;
const languageTranslation = require('../../languagesupport/src/LanguageData/Services.json')
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { group, Path, userDummyImage } from './assets'
import parse from 'html-react-parser';
import Pagination from '@material-ui/lab/Pagination';
// Customizable Area End

export default class ServiceType extends ServiceTypeController {

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <AppBackground variant="white">
           <Container style={webStyle.bannerMainContainer}>
            {<SectionMain maxWidth={false} style={webStyle.section1}>
              {<GridBox container spacing={2} style={webStyle.firstRow}>
                <TopLeftGridBox item xs={12} sm={7} lg={7} justifyContent="center" direction="column" style={webStyle.sectionOne}>
                  <Typography variant="h4" className="sectionOneTitle">
                    {this.state.currentLanguage !== 'en' ? this.state.bannerData.attributes.banner_title_arabic : this.state.bannerData.attributes.banner_title} <span style={webStyle.highlightText}>{this.state.bannerData.attributes.sub_text1}</span> {this.state.bannerData.attributes.sub_text2}
                  </Typography>
                  <Typography variant="body1" style={webStyle.sectionOneDetail}>
                    {parse(`${this.state.currentLanguage !== 'en' ? this.state.bannerData.attributes.banner_description_arabic : this.state.bannerData.attributes.banner_description}`)}
                  </Typography>
                </TopLeftGridBox>
                <BannerImage item xs={12} sm={5} lg={5}>
                  <img className="img-banner" style={webStyle.imageOneimage} src={this.state.bannerData.attributes.image} alt="Frame" />
                </BannerImage>
              </GridBox>}
            </SectionMain>
            }
          </Container>
        <Container style={{ backgroundColor: 'white', marginTop: '-50px' }}>
            <Grid style={useStyles.filterGrid} container spacing={8}>
                <Grid item xs={12} sm={12} md={9} lg={9} style={{ maxWidth: "100%" }}>
                    {
                      this.state.activeBtn === "Listings" || this.state.activeBtn === "41C'*" ?
                      <TextField
                        data-test-id="searchInputTestID"
                        style={useStyles.filterInput}
                        variant="outlined"
                        fullWidth
                        placeholder={languageTranslation[this.state.currentLanguage].Search}
                        value={this.state.search}
                        onChange={this.handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={useStyles.searchIcon} />
                                </InputAdornment>
                            ),
                        }}
                      />
                      :
                      <TextField
                        data-test-id="searchInputBuyerTestID"
                        style={useStyles.filterInput}
                        variant="outlined"
                        fullWidth
                        placeholder={languageTranslation[this.state.currentLanguage].Search}
                        value={this.state.buyerSearch}
                        onChange={this.handleBuyerSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={useStyles.searchIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    }
                  <Box style={{ borderBlockEnd: '1px solid #CBD5E1' }}>
                    <CustomBox >
                      <CustomTab data-test-id='custom-tabs'
                        data={languageTranslation[this.state.currentLanguage].ServiceTabData}
                        activeBtn={this.state.activeBtn}
                        handleTab={this.handleTab} />
                    </CustomBox>
                  </Box>
                </Grid>                
                    {(this.state.activeBtn === "Listings" || this.state.activeBtn === "41C'*") &&
                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={12} md={3} lg={3} style={useStyles.formFilters}>
                        <Box style={useStyles.filterContainer}>
                          <Typography style={useStyles.filterTitle}>{languageTranslation[this.state.currentLanguage].Filters}</Typography>
                          <Accordion style={useStyles.accordion} expanded={this.state.expanded.debtSize} onChange={this.handleAccordionChange('debtSize')}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="debt-size-content"
                              data-test-id="debt-size-header">
                              <Typography style={useStyles.accordionTitle}>{languageTranslation[this.state.currentLanguage].DebtSize}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormGroup>
                                <FormControlLabel
                                  data-test-id="debtcheckboxID"
                                  control={
                                    <CustomCheckbox
                                      value={this.state.debtSize.under10000}
                                      checked={this.state.debtSize.under10000}
                                      onChange={(event) => this.handleInputChange('debt_size', { ["debt_size[from]"]: "0", ["debt_size[to]"]: "10000" }, "debtSize", "under10000")}
                                      name="under10000" />
                                  }
                                  label={languageTranslation[this.state.currentLanguage].Under10000}
                                  style={useStyles.formControlLabel}
                                />
                                <FormControlLabel
                                  data-test-id="debtcheckboxSizeSecID"
                                  control={
                                    <CustomCheckbox
                                      value={this.state.debtSize.between10000and50000}
                                      checked={this.state.debtSize.between10000and50000}
                                      onChange={(event) => this.handleInputChange('debt_size', { ["debt_size[from]"]: "10000", ["debt_size[to]"]: "50000" }, "debtSize", "between10000and50000")}
                                      name="10000 - 50000" />
                                  }
                                  label={languageTranslation[this.state.currentLanguage].debtSize2}
                                  style={useStyles.formControlLabel}
                                />
                                <FormControlLabel
                                  data-test-id="debtcheckboxSizeThirdID"
                                  control={
                                    <CustomCheckbox
                                      value={this.state.debtSize.between50000and100000}
                                      checked={this.state.debtSize.between50000and100000}
                                      onChange={(event) => this.handleInputChange('debt_size', { ["debt_size[from]"]: "50000", ["debt_size[to]"]: "100000" }, "debtSize", "between50000and100000")}
                                      name="between50000and100000"
                                    />
                                  }
                                  label={languageTranslation[this.state.currentLanguage].debtSize3}
                                  style={useStyles.formControlLabel}
                                />
                                <FormControlLabel
                                  data-test-id="debtcheckboxSizeFourthID"
                                  control={
                                    <CustomCheckbox
                                      checked={this.state.debtSize.over100000}
                                      onChange={(event) => this.handleInputChange('debt_size', { ["debt_size[from]"]: "100000", ["debt_size[to]"]: null }, "debtSize", "over100000")}
                                      name="over100000"
                                    />
                                  }
                                  label={languageTranslation[this.state.currentLanguage].debtSize4}
                                  style={useStyles.formControlLabel}
                                />
                              </FormGroup>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion style={useStyles.accordion} expanded={this.state.expanded.debtAgeRange} onChange={this.handleAccordionChange('debtAgeRange')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography style={useStyles.accordionTitle}>{languageTranslation[this.state.currentLanguage].DebtAgeRange}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormGroup>
                                <FormControlLabel
                                  data-test-id="debtAgeRangeId"
                                  control={
                                    <CustomCheckbox
                                      checked={this.state.debtAgeRanges.under30days}
                                      onChange={(event) => this.handleInputChange('age_range', { ["age_range[from]"]: "0", ["age_range[to]"]: "30" }, "debtAgeRanges", "under30days")}
                                      name="20"
                                    />
                                  }
                                  label="Under 30 days"
                                  style={useStyles.formControlLabel}
                                />
                                <FormControlLabel
                                  data-test-id="debtAgeRange3under30Id"
                                  control={
                                    <CustomCheckbox
                                      checked={this.state.debtAgeRanges.from30to60days}
                                      onChange={(event) => this.handleInputChange('age_range', { ["age_range[from]"]: "30", ["age_range[to]"]: "60" }, "debtAgeRanges", "from30to60days")}
                                      name="30 - 50"
                                    />
                                  }
                                  label="30-60 days"
                                  style={useStyles.formControlLabel}
                                />
                                <FormControlLabel
                                  data-test-id="debtAgeRangefrom60to90daysId"
                                  control={
                                    <CustomCheckbox
                                      checked={this.state.debtAgeRanges.from60to90days}
                                      onChange={(event) => this.handleInputChange('age_range', { ["age_range[from]"]: "60", ["age_range[to]"]: "90" }, "debtAgeRanges", "from60to90days")}
                                      name="60-90"
                                    />
                                  }
                                  label="60-90 days"
                                  style={useStyles.formControlLabel}
                                />
                                <FormControlLabel
                                  data-test-id="debtAgeRangeover90daysId"
                                  control={
                                    <CustomCheckbox
                                      checked={this.state.debtAgeRanges.over90days}
                                      onChange={(event) => this.handleInputChange('age_range', { ["age_range[from]"]: "90", ["age_range[to]"]: null }, "debtAgeRanges", "over90days")}
                                      name="90"
                                    />
                                  }
                                  label="Over 90 days"
                                  style={useStyles.formControlLabel}
                                />
                              </FormGroup>
                            </AccordionDetails>
                          </Accordion>

                          <Accordion style={useStyles.accordion} expanded={this.state.expanded.debtOwnerType} onChange={this.handleAccordionChange('debtOwnerType')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography style={useStyles.accordionTitle}>Debt Owner Type</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormGroup>
                                <FormControlLabel
                                  data-test-id="individualId"
                                  control={
                                    <CustomCheckbox
                                      checked={this.state.debtOwnerTypes.individual}
                                      onChange={(event) => this.handleInputChange('owner_type', 'Individual', "debtOwnerTypes", "individual")}
                                      name="Individual"
                                    />
                                  }
                                  label="Individual"
                                  style={useStyles.formControlLabel}
                                />
                                <FormControlLabel
                                  data-test-id="bankId"
                                  control={
                                    <CustomCheckbox
                                      checked={this.state.debtOwnerTypes.bank}
                                      onChange={(event) => this.handleInputChange('owner_type', 'Bank', "debtOwnerTypes", "bank")}
                                      name="bank"
                                    />
                                  }
                                  label="Bank"
                                  style={useStyles.formControlLabel}
                                />
                                <FormControlLabel
                                  data-test-id="agencyId"
                                  control={
                                    <CustomCheckbox
                                      checked={this.state.debtOwnerTypes.agency}
                                      onChange={(event) => this.handleInputChange('owner_type', 'Agency', "debtOwnerTypes", "agency")}
                                      name="Agency"
                                    />
                                  }
                                  label="Agency"
                                  style={useStyles.formControlLabel}
                                />
                              </FormGroup>
                            </AccordionDetails>
                          </Accordion>
                          <ApplyButton onClick={() => this.fetchOffersFilterData()}>
                            Apply
                          </ApplyButton>
                        </Box>
                      </Grid>
                    <ServiceBoxContainer item xs={12} sm={12} md={9} lg={9}>
                      <Grid container spacing={3}>
                        {this.state.userList.length >= 1 ? this.state.userList.map((card, index) => (
                          <Grid key={index} item xs={12} sm={12} md={12}>
                            <Box style={useStyles.companyBox} onClick={() => this.handleListingDetails(card)}>
                              <Box>
                                <Typography style={useStyles.companyName}>{card.attributes.listing_title}</Typography>
                                <Box style={useStyles.companyContainer} >
                                  <CardStatus>
                                    {card.attributes.current_recovery_status}
                                  </CardStatus>
                                  <Typography style={useStyles.companyPost}>{languageTranslation[this.state.currentLanguage].Posted}  {this.timeAgo(card.attributes.created_at)}</Typography>
                                </Box>
                              </Box>
                              <Divider />
                              <Box style={useStyles.companyInfo}>
                                <Box style={useStyles.companyInfoContainer}>
                                  <Box>
                                    <MonetizationOnOutlinedIcon />
                                  </Box>
                                  <Box sx={useStyles.companyValueContainer}>
                                    <Typography style={useStyles.companyInfoHead}>{card.attributes.portfolio_size}</Typography>
                                    <Typography style={useStyles.companyInfoDescription} >{configJSON.companyPortfolio}</Typography>
                                  </Box>
                                </Box>

                                <Box style={useStyles.companyInfoContainer}>
                                  <Box>
                                    <img style={{ height: "18.67px", width: "18.67px" }}
                                      src={Path}
                                      alt="icon"
                                    />
                                  </Box>
                                  <Box sx={useStyles.companyValueContainer}>
                                    <Typography style={useStyles.companyInfoHead} >{card.attributes.account_count}</Typography>
                                    <Typography style={useStyles.companyInfoDescription}  >{configJSON.companyAccount}</Typography>
                                  </Box>
                                </Box>

                                <Box style={useStyles.companyInfoContainer}>
                                  <Box>
                                    <img
                                      style={{ height: "18.85px", width: "18.84px" }}
                                      src={group}
                                      alt="icon"
                                    />
                                  </Box>
                                  <Box sx={useStyles.companyValueContainer}>
                                    <Typography style={useStyles.companyInfoHead}>{card.attributes.avg_balance_per_account}</Typography>
                                    <Typography style={useStyles.companyInfoDescription} >{configJSON.averageAcoountBalance}</Typography>
                                  </Box>
                                </Box>

                              </Box>
                            </Box>
                          </Grid>
                        )) : ""}
                      </Grid>

                      <Box style={webStyle.paginationBox}>
                        <Grid container justifyContent="flex-end" alignItems="center">
                          <CustomPagination
                            data-test-id="tablePaginationTestID"
                            count={this.state.totalPages}
                            page={this.state.currentPage}
                            onChange={this.handlePageChange}
                            shape="rounded"
                            variant="outlined"
                            siblingCount={1}
                            boundaryCount={1}
                          />
                        </Grid>
                        <Typography align="right" style={webStyle.paginationCount}>
                          {`${(this.state.currentPage - 1) * this.state.itemsPerPage + 1} - ${Math.min(this.state.currentPage * this.state.itemsPerPage, this.state.totalCount)} of ${this.state.totalCount} results`}
                        </Typography>
                      </Box>
                    </ServiceBoxContainer>
                    </Grid>
                    }
                    {(this.state.activeBtn === 'Buyer profiles' || this.state.activeBtn === "91H6") &&
                  <Grid container spacing={6}>
                    <Grid item xs={12} sm={12} md={3} lg={3} style={useStyles.formFilters}>
                      <Box style={useStyles.filterContainer}>
                        <Typography style={useStyles.filterTitle}>{languageTranslation[this.state.currentLanguage].Filters}</Typography>
                        <Accordion style={useStyles.accordion} expanded={this.state.expanded.buyerType} onChange={this.handleAccordionChange('buyerType')}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="buyer-type-content" data-test-id="buyer-type-header">
                            <Typography style={useStyles.accordionTitle}>Buyer Type</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormGroup>
                              <FormControlLabel
                                data-test-id="buyerCheckbox1"
                                control={
                                  <CustomCheckbox
                                    checked={this.state.buyerType.individual}
                                    name="Individual"
                                    onChange={() => this.handleBuyerInputChange("buyer_type", "Individual", "buyerType", "individual")}
                                  />
                                }
                                label="Individual"
                                style={useStyles.formControlLabel}
                              />
                              <FormControlLabel
                                data-test-id="buyerCheckbox2"
                                control={
                                  <CustomCheckbox
                                    checked={this.state.buyerType.bank}
                                    name="Bank"
                                    onChange={() => this.handleBuyerInputChange("buyer_type", "Bank", "buyerType", "bank")}
                                  />
                                }
                                label="Bank"
                                style={useStyles.formControlLabel}
                              />
                              <FormControlLabel
                                data-test-id="buyerCheckbox3"
                                control={
                                  <CustomCheckbox
                                    checked={this.state.buyerType.agency}
                                    name="Agency"
                                    onChange={() => this.handleBuyerInputChange("buyer_type", "Agency", "buyerType", "agency")}
                                  />
                                }
                                label="Agency"
                                style={useStyles.formControlLabel}
                              />
                              <FormControlLabel
                                data-test-id="buyerCheckbox4"
                                control={
                                  <CustomCheckbox
                                    checked={this.state.buyerType.serviceProvider}
                                    name="Service Provider"
                                    onChange={() => this.handleBuyerInputChange("buyer_type", "Service Provider", "buyerType", "serviceProvider")}
                                  />
                                }
                                label="Service Provider"
                                style={useStyles.formControlLabel}
                              />
                            </FormGroup>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion style={useStyles.accordion} expanded={this.state.expanded.debtSize} onChange={this.handleAccordionChange('debtSize')}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="debt-size-content" data-test-id="debt-size-header">
                            <Typography style={useStyles.accordionTitle}>Debt Size</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormGroup>
                              <FormControlLabel
                              data-test-id="buyerCheckbox5"
                                control={
                                  <CustomCheckbox
                                    checked={this.state.debtSizeBuyer.under10000}
                                    name="Under 10000"
                                    onChange={() => this.handleBuyerInputChange("debt_size", { "debt_size[from]": "0", "debt_size[to]" : "10000" }, "debtSizeBuyer", "under10000")}
                                  />
                                }
                                label="Under $10,000"
                                style={useStyles.formControlLabel}
                              />
                              <FormControlLabel
                                data-test-id="buyerCheckbox6"
                                control={
                                  <CustomCheckbox
                                    checked={this.state.debtSizeBuyer.between10000and50000}
                                    name="10000-50000"
                                    onChange={() => this.handleBuyerInputChange("debt_size", { "debt_size[from]": "10000", "debt_size[to]" : "50000" }, "debtSizeBuyer", "between10000and50000")}
                                  />
                                }
                                label="$10,000 - $50,000"
                                style={useStyles.formControlLabel}
                              />
                              <FormControlLabel
                                data-test-id="buyerCheckbox7"
                                control={
                                  <CustomCheckbox
                                    checked={this.state.debtSizeBuyer.between50000and100000}
                                    name="50000-100000"
                                    onChange={() => this.handleBuyerInputChange("debt_size", { "debt_size[from]": "50000", "debt_size[to]" : "100000" }, "debtSizeBuyer", "between50000and100000")}
                                  />
                                }
                                label="$50,000 - $100,000"
                                style={useStyles.formControlLabel}
                              />
                              <FormControlLabel
                                data-test-id="buyerCheckbox8"
                                control={
                                  <CustomCheckbox
                                    checked={this.state.debtSizeBuyer.over100000}
                                    name="Over100000"
                                    onChange={() => this.handleBuyerInputChange("debt_size", { "debt_size[from]": "100000", "debt_size[to]" : null }, "debtSizeBuyer", "over100000")}
                                  />
                                }
                                label="Over $100,000"
                                style={useStyles.formControlLabel}
                              />
                            </FormGroup>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion style={useStyles.accordion} expanded={this.state.expanded.debtServicesOffered} onChange={this.handleAccordionChange('debtServicesOffered')}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="debt-services-content" data-test-id="debt-services-header">
                            <Typography style={useStyles.accordionTitle}>Debt Services Offered</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormGroup>
                              <FormControlLabel
                                data-test-id="buyerCheckbox9"
                                control={
                                  <CustomCheckbox
                                    checked={this.state.debtServicesOffered.debtCollection}
                                    name="Debt Collection"
                                    onChange={() => this.handleBuyerInputChange("services", "Debt Collection", "debtServicesOffered", "debtCollection")}
                                  />
                                }
                                label="Debt Collection"
                                style={useStyles.formControlLabel}
                              />
                              <FormControlLabel
                                data-test-id="buyerCheckbox10"
                                control={
                                  <CustomCheckbox
                                    checked={this.state.debtServicesOffered.legalServices}
                                    name="Legal Services"
                                    onChange={() => this.handleBuyerInputChange("services", "Legal Services", "debtServicesOffered", "legalServices")}
                                  />
                                }
                                label="Legal Services"
                                style={useStyles.formControlLabel}
                              />
                              <FormControlLabel
                                data-test-id="buyerCheckbox11"
                                control={
                                  <CustomCheckbox
                                    checked={this.state.debtServicesOffered.investmentServices}
                                    name="Investment Services"
                                    onChange={() => this.handleBuyerInputChange("services", "Investment Services", "debtServicesOffered", "investmentServices")}
                                  />
                                }
                                label="Investment Services"
                                style={useStyles.formControlLabel}
                              />
                              <FormControlLabel
                                data-test-id="buyerCheckbox12"
                                control={
                                  <CustomCheckbox
                                    checked={this.state.debtServicesOffered.financialAdvisory}
                                    name="Financial Advisory"
                                    onChange={() => this.handleBuyerInputChange("services", "Financial Advisory", "debtServicesOffered", "financialAdvisory")}
                                  />
                                }
                                label="Financial Advisory"
                                style={useStyles.formControlLabel}
                              />
                            </FormGroup>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion style={useStyles.accordion} expanded={this.state.expanded.experienceLevel} onChange={this.handleAccordionChange('experienceLevel')}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="experience-level-content" data-test-id="experience-level-header">
                            <Typography style={useStyles.accordionTitle}>Experience Level</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormGroup>
                              <FormControlLabel
                                data-test-id="buyerCheckbox13"
                                control={
                                  <CustomCheckbox
                                    checked={this.state.experienceLevel.under1year}
                                    name="Under 1 year"
                                    onChange={() => this.handleBuyerInputChange("experience", { "experience[from]": "0", "experience[to]" : "1" }, "experienceLevel", "under1year")}
                                  />
                                }
                                label="Under 1 year"
                                style={useStyles.formControlLabel}
                              />
                              <FormControlLabel
                                data-test-id="buyerCheckbox14"
                                control={
                                  <CustomCheckbox
                                    checked={this.state.experienceLevel.between1and3years}
                                    name="1-3 years"
                                    onChange={() => this.handleBuyerInputChange("experience", { "experience[from]": "1", "experience[to]" : "3" }, "experienceLevel", "between1and3years")}
                                  />
                                }
                                label="1-3 years"
                                style={useStyles.formControlLabel}
                              />
                              <FormControlLabel
                                data-test-id="buyerCheckbox15"
                                control={
                                  <CustomCheckbox
                                    checked={this.state.experienceLevel.between3and5years}
                                    name="3-5 years"
                                    onChange={() => this.handleBuyerInputChange("experience", { "experience[from]": "3", "experience[to]" : "5" }, "experienceLevel", "between3and5years")}
                                  />
                                }
                                label="3-5 years"
                                style={useStyles.formControlLabel}
                              />
                              <FormControlLabel
                                data-test-id="buyerCheckbox16"
                                control={
                                  <CustomCheckbox
                                    checked={this.state.experienceLevel.over5years}
                                    name="Over 5 years"
                                    onChange={() => this.handleBuyerInputChange("experience", { "experience[from]": "5", "experience[to]" : null }, "experienceLevel", "over5years")}
                                  />
                                }
                                label="Over 5 years"
                                style={useStyles.formControlLabel}
                              />
                            </FormGroup>
                          </AccordionDetails>
                        </Accordion>
                        <ApplyButton onClick={() => this.fetchBuyersFilterData()}>
                          Apply
                        </ApplyButton>
                      </Box>
                    </Grid>
                    <ServiceBoxContainer item xs={12} sm={12} md={9} lg={9}>
                      <Grid container spacing={3}>
                        {this.state.buyersProfile.length >= 1 ? this.state.buyersProfile.map((card, index) => (
                          <Grid key={index} item xs={12} sm={12} md={12}>

                            <Box style={useStyles.companyBox} onClick={() => this.handleListSummery(card)}>
                              <Box>
                                <Box style={useStyles.header}>
                                  <Box>
                                    <img style={webStyle.byuersProfileImage} src={card.attributes.image?.url || userDummyImage} />
                                  </Box>
                                  <Box>
                                    <Typography style={useStyles.title}>
                                      {card.attributes.name}
                                    </Typography>
                                    <Typography style={webStyle.buyerWebsite}>{card.attributes.website}</Typography>
                                  </Box>
                                </Box>
                                <Divider style={useStyles.divider} />
                                <Box sx={useStyles.services}>
                                  <Typography style={useStyles.servicesTitle}>
                                    {languageTranslation[this.state.currentLanguage].ServicesOffered}
                                  </Typography>
                                  <Grid container spacing={2}>
                                    {card.attributes.services.map((serviceData, index) => (
                                      <Grid item key={index}>
                                        <Chip label={serviceData} style={useStyles.chip} />
                                      </Grid>
                                    ))}
                                  </Grid>

                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        )) : ""}
                      </Grid>
                      <Box style={webStyle.paginationBox}>
                        <Grid container justifyContent="flex-end" alignItems="center">
                          <CustomPagination
                            data-test-id="tablePaginationSecondTestID"
                            count={this.state.totalBuyersPages}
                            page={this.state.currentBuyersPage}
                            onChange={this.handleBuyersPageChange}
                            shape="rounded"
                            variant="outlined"
                            siblingCount={1}
                            boundaryCount={1}
                          />
                        </Grid>
                        <Typography align="right" style={webStyle.paginationCount}>
                          {`${(this.state.currentBuyersPage - 1) * this.state.itemsPerPage + 1} - ${Math.min(this.state.currentBuyersPage * this.state.itemsPerPage, this.state.totalBuyersCount)} of ${this.state.totalBuyersCount} results`}
                        </Typography>
                      </Box>
                    </ServiceBoxContainer>
                  </Grid>
                    }
            </Grid>
        </Container>
    </AppBackground>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const CustomCheckbox = withStyles({
  root: {
    '& .MuiSvgIcon-root': {
      color: '#64748B',
    },
    '&.Mui-checked': {
      '& .MuiSvgIcon-root': {
        color: '#1D4ED8',
      },
    },
  },
})(Checkbox);

const CustomPagination = withStyles({
  ul: {
    '& .MuiPaginationItem-root': {
      color: '#0F172A',
      border: 'none',
    },
    '& .Mui-selected': {
      color: '#1D4ED8',
      fontWeight: 'bold',
      background: 'none',
    },
    '& .MuiPaginationItem-ellipsis': {
      color: '#1F2937',
    },
    '& .MuiPaginationItem-icon': {
      color: '#0F172A',
    },
    '& .MuiPaginationItem-page:not(.Mui-selected)': {
      textDecoration: 'underline',
      textUnderlineOffset: '3px',
    }
  }
})(Pagination);

const ServiceBoxContainer = styled(Grid)({
  marginBottom: "60px",
  "& .MuiGrid-spacing-xs-3 > .MuiGrid-item": {
    padding: deviceBasedDynamicDimension(16, true, 1)
  }
})
const GridBox = styled(Grid)({
  '& .sectionOneTitle': {
    color: '#000',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '52px',
    fontSize: '36px',
    marginBottom: '24px',

    '@media (max-width: 1200px)': {
      fontSize: '25px',
      lineHeight: '30.205px',
    }
  },
  '& .img-banner': {
    '@media (max-width: 1200px)': {
      width: '80%',
    }
  }
})
const SectionMain = styled(Container)({
  padding: '90px 40px',
  backgroundSize: "auto 100%",
  "@media (max-width: 960px)": {
    padding: "40px 20px"
  },
  "@media (max-width: 600px)": {
    padding: "40px 20px",
  },
  "@media (min-width: 1440px)": {
    backgroundSize: "auto 115%",
  },
  '& .MuiInputBase-input-root': {
    padding: 12
  },
  '& .MuiInputBase-input:focus': {
    outline: 'solid'
  }
});
const TopLeftGridBox = styled(Grid)({
  '@media (max-width: 840px)': {
    flexBasis: "100%",
    maxWidth: "100%"
  }
});
const BannerImage = styled(Grid)({
  alignItems: "flex-start",
  display: "flex",
  justifyContent: "center",
  "@media (max-width: 960px)": {
    alignItems: "center",
  },
  '@media (max-width: 840px)': {
    maxWidth: "100%",
    flexBasis: "100%",
  },
  "@media (max-width: 600px)": {
    alignItems: "center",
  },
})
const ApplyButton = styled(Button)({
  marginTop: 2,
    fontStyle: "inter",
    textTransform: "none",
    width: "248px",
    height: "44px",
    borderRadius: "8px",
    color: "#FFFFFF",
    backgroundColor: "#1E3A8A",
    fontSize: "14px",
    fontWeight: "bold",
    marginLeft: '-12px',
    '&:hover': {
      backgroundColor: '#1E3A8A',
      boxShadow: 'none',
    },
});
const CardStatus = styled(Typography)({
  fontSize: '12px',
  fontWeight: 700,
  backgroundColor: '#D1FAE5',
  color: '#059669',
  borderRadius: '12px',
  padding: '4px 12px',
  textTransform: 'uppercase'
});
const webStyle = {
  paginationCount: {
    whiteSpace: "nowrap",
    color: "#64748B",
    fontSize: "12px",
    fontWeight: 400
  },
  paginationBox: {
    display: "flex",
    alignItems: "center",
    gap: "200px",
    marginTop:"24px"
  },
  section1: {
    height: "auto",
    justifyContent: "center",
    maxWidth: "none",
    alignItems: 'center',
    display: 'flex',
  },
  firstRow: {
    maxWidth: "1200px",
    margin: "0px auto",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  sectionOne: {
    display: "flex",
  },
  sectionOneDetail: {
    color: "#0F172A",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    width: '100%',
    lineHeight: "26px"
  },
  imageOneimage: {
    width: "90%",
    maxWidth: "488px"
  },
  highlightText: {
    backgroundColor: "#DBEAFE",
    color: "#1D4ED8",
    borderRadius: 16,
    padding: "2px 5px",
    whiteSpace: "nowrap"
  } as React.CSSProperties,
  bannerMainContainer: {
    padding: '40px 0px',
    maxWidth: '100%'
  },
  byuersProfileImage: {
    height: "100px",
    width: "100px",
    border: "2px solid #DBEAFE",
    borderRadius: "100px",
  },
  buyerWebsite: {
    textDecoration: "underline",
    fontSize: "14px",
    fontWeight: 400,
    color: "#334155"
  }
}

const useStyles = {
  companyBox: {
    padding: '40px',
    borderRadius: '24px',
    boxShadow: '0px 4px 4px 2px #0000000F, 0 6px 20px 0 #0000000F',
  },

  companyContainer: {
    display: 'flex',
    padding: "20px 0px",
    gap: '24px',
    alignItems: 'center'
  },
  companyName: {
    fontSize: '24px',
    fontWeight: 700
  },
  companyPost: {
    fontSize: '12px',
    color: '#6B7280'
  },

  companyInfo: {
    display: "flex",
    gap: '72px',
    paddingTop: '40px'
  },
  companyInfoContainer: {
    display: 'flex',
    gap: '12px'
  },
  companyInfoHead: {
    color: '#0F172A',
    fontSize: '18px',
    fontWeight: 600
  },
  companyInfoDescription: {
    color: '#334155',
    fontSize: '16px',
    fontWeight: 400
  },
  companyInfoBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  companyValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },

  serviceBox: {
    height: "418px",
    backgroundColor: "white",
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0',
    marginRight: '10rem',
    "@media (max-width:380px)": {
      height: "480px",
    },
  },
  ServiceContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    width: '545px',
    marginTop: '2rem'
  },
  serviceDescBox: {
    width: "545px",
    fontSize: "18px",
    "@media (max-width:768px)": {
      fontSize: "14px",
      width: "100%",
    },
  },
  ServiceBtn: {
    width: "315px",
    height: "44px",
    fontSize: "14px",
    color: "#fff",
    backgroundColor: "#1E3A8A",
    borderRadius: "8px",
    marginBottom: "45px",
    textTransform: "capitalize",
    "@media (max-width:520px)": {
      width: "250px",
      height: "44px",
    },
  },
  filterGrid: {
    marginTop: "80px",
    marginBottom: "20px",
    "@media (max-width:960px)": {
      marginTop: "5px",
    },
  },
  filterCatagoryInput: {
    "& .MuiInputBase-input": {},
    "& .MuiOutlinedInput-root": {
      height: "44px",
    },
  },
  filterInput: {
    marginBottom: '18px',
    width: ' 1220px',
    padding: '10px 8px 10px 8px',
    border: '1px',
    borderRadius: '8px',

    "& .MuiInputBase-input": {},
    "& .MuiOutlinedInput-root": {
      height: "44px",
    },
    "@media (max-width:580px)": {
      marginTop: "10px",
      marginLeft: "0",
    },
  },
  filterResultTxt: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
  },
  CatagoryWraper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  serviceCardWrapper: {
    display: " flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: '272px',
    width: "100%",
    borderRadius: "10px",
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },
  serviceImgWrapper: {
    width: "100%",
    height: "240px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    position: "relative",
  },
  serviceImg: {
    height: "80%",
    width: "80%",
    objectFit: "cover"
  },
  cardBox: {
    display: "flex",
    width: "100%",
    position: "absolute",
    bottom: "12px",
    left: "14px"
  },
  searchIcon: {
    color: "94A3B8"
  },
  filterContainer: {
    marginLeft: "50px"
  },
  filterTitle: {
    fontSize: "20px",
    fontWeight: 600
  },
  formFilters: {
    marginLeft: "-13px"
  },
  accordion: {
    border: "none",
    boxShadow: "none"
  },
  accordionTitle: {
    marginLeft: '-16px',
    fontSize: "16px",
    fontWeight: "bold",
    color: "#0F172A",

  },
  formControlLabel: {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 400,
    marginLeft: '-28px',
  },

  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    borderRadius: '24px',
    boxShadow: '0px 4px 4px 2px #0000000F, 0 6px 20px 0 #0000000F'
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    alignItems: 'flex-start',
    padding: '30px',
    width: '895px',
    background: 'white',
    borderRadius: '24px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  header: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    marginBottom: "24px"
  },
  title: {
    fontWeight: 'bold',
    fontSize: '24px',
    color: '#0F172A',
  },
  link: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#334155',
  },
  divider: {
    width: '100%',
    backgroundColor: '#E2E8F0',
  },
  services: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    paddingTop: '40px',
  },
  servicesTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
  },
  chip: {
    padding: '8px 16px 8px 16px',
    backgroundColor: '#E2E8F0',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#334155',
  },
  pagination: {
    marginTop: '50px',
    display: 'flex',
    // justifyContent: 'right',
    marginLeft: "400px"
  }

};
export { ServiceType };
// Customizable Area End
