import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Modal,
    Typography,
    styled,
    Grid,
    Badge,
    Avatar,
    TextField,
    IconButton,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Menu,
    Divider
} from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import NotesIcon from "@material-ui/icons/Notes";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import EditIcon from "@material-ui/icons/Edit";
import { blankBusinessProfile, fileUploadIcon } from "./assets";
// Customizable Area End

import BusinessProfileController, {
    Props,
    configJSON,
} from "./BusinessProfileController";

// Customizable Area Start
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
const languageTranslationData = require('../../languagesupport/src/LanguageData/BusinessProfile.json');

interface DocResponse {
    file_name: string;
    url: string;
    size: string;
};
// Customizable Area End

export default class BusinessProfile extends BusinessProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderBlank = () => {
        const languageData = languageTranslationData[this.state.selectedLanguage];
        return (
            <Box style={webStyle.blankWrapper}>
                <Box style={webStyle.blankBodyBox}>
                    <img src={blankBusinessProfile} alt="blank_image" width="270px"/>
                    <Box>
                        <Typography style={webStyle.blankParagraphText}>
                            {languageData.blankProfileParagraphFir}
                        </Typography>
                        <Typography style={webStyle.blankParagraphText}>
                            {languageData.blankProfileParagraphSec}
                        </Typography>
                    </Box>
                    <SaveButton 
                        data-test-id="createListingTestID" 
                        style={{width:"245px", height: "44px"}} 
                        onClick={()=>this.handleEditModalOpen(true)}
                    >
                        <Box style={{...webStyle.createButtonInBox, whiteSpace: "nowrap"}}>
                            <AddCircleOutlineIcon/> 
                            {languageData.CreateBusinessProfile}
                        </Box>
                    </SaveButton>
                </Box>
            </Box>
        );
    };

    renderCustomGrid = (label:string, value: string, placeholder:string, error:string, stateName: string) => {
        const {editModalOpen} = this.state
        return (
            <CustomGrid item xs={12} sm={12} md={6}>
                <Typography>{label}</Typography>
                {stateName==="website"?
                    <WebsiteTextField
                        data-test-id={`${stateName}TestID`}
                        value = {value}
                        name = {stateName}
                        size="small"
                        fullWidth
                        variant="outlined"
                        placeholder={placeholder}
                        disabled = {!editModalOpen}
                        onChange={this.handleInputChange}
                        style={{textDecoration: value? "underline": "none"}}
                    />:
                    <TextField
                        data-test-id={`${stateName}TestID`}
                        value = {value}
                        name = {stateName}
                        size="small"
                        fullWidth
                        variant="outlined"
                        placeholder={placeholder}
                        disabled = {!editModalOpen}
                        onChange={this.handleInputChange}
                    />
                }
                {(error && this.state.saveCheck) && (
                    <Typography style={webStyle.errorText}>
                      {error}
                    </Typography>
                )}
            </CustomGrid>
        );
    };

    renderServiceInput = () => {
        const fieldLabel = languageTranslationData[this.state.selectedLanguage].inputFields;
        const {errors, editModalOpen} = this.state;
        return (
            <CustomGrid item xs={12} sm={12} md={12}>
            <Typography>{fieldLabel.Servicesoffered}</Typography>
            <Box
              style={{...webStyle.serviceWrapper, gap:editModalOpen? "4px": "10px", padding:editModalOpen? "0px": "10px"}}
            >
              {this.state.serviceArray.map((value, index) => (
                <Box
                  key={index}
                  style={editModalOpen? webStyle.serviceBox: webStyle.busiServiceBox}
                >
                  <Typography
                    style={webStyle.seviceItemText}
                  >
                    {value}
                  </Typography>
                  {editModalOpen && <IconButton data-test-id={`serviceItemTest${index}`} onClick={()=>this.deleteServiceItem(index)}>
                    <CloseRoundedIcon
                      style={{ width: "20px", height: "20px" }}
                    />
                  </IconButton>}
                </Box>
              ))}
              {editModalOpen && <ServiceTextField
                data-test-id="serviceTestID"
                disabled = {!editModalOpen}
                name = "service"
                size="small"
                value={this.state.serviceInput}
                variant="outlined"
                placeholder={this.state.serviceArray.length === 0 ? fieldLabel.Servicesoffered : ""}
                style={{ flexGrow: 1 }}
                onChange={this.handleServiceArray}
                onBlur={this.handleServiceBlur}
              />}
            </Box>
            {(errors.serviceErr && this.state.saveCheck) && (
                <Typography style={webStyle.errorText}>
                    {errors.serviceErr}
                </Typography>
            )}
          </CustomGrid>
        )
    }

    renderEmptyPortfolio = (index:number) =>{
        const {inputFields} = languageTranslationData[this.state.selectedLanguage];
        const { errors, saveCheck } = this.state;
        return (
            <CustomGrid key={index} item xs={12} sm={12} md={6}>
                <Typography>{inputFields.Portfoliodocument}</Typography>
                <Box style={webStyle.porfolioBox}>
                    <EmptyUploadIcon 
                        src={fileUploadIcon} 
                        style={{ fontSize: "22px", color: "#94A3B8" }}
                        alt= "file_upload_icon"
                    />
                    <Typography style={webStyle.upPortText}>
                        {inputFields.UploadPortfolio}
                    </Typography>
                    <PortfolioBodyBox>
                        <Typography style={webStyle.portFolioBodyText}>{inputFields.docSuppFor}</Typography>
                        <Typography style={webStyle.portFolioBodyText}>{configJSON.formatSupport}</Typography>
                    </PortfolioBodyBox>
                    <FileButtonBox 
                        style={{width:"137px"}}
                        component={"label"}
                    >
                        {inputFields.ChooseaFile}
                        <VisuallyHiddenInput type="file" data-test-id={`chooseFileTestID${index}`} onChange={(event)=>this.handleAddPortfolio(event, index)} />
                    </FileButtonBox>
                </Box>
                {(errors.portfolioErr && saveCheck) && (
                    <Typography style={webStyle.errorText}>
                        {errors.portfolioErr}
                    </Typography>
                )}
            </CustomGrid>
        )
    };

    renderPortfolioFile = (file:File | DocResponse | null, index:number) => {
        const languageData = languageTranslationData[this.state.selectedLanguage];
        const fileData = this.handleFileOrDoc(file);
        return (
            <CustomGrid key={index} item xs={12} sm={12} md={6}>
                <Typography>
                    {languageData.Portfolio}
                </Typography>
                <Box style={webStyle.fileInputWrapper}>
                    <Box style={webStyle.noteIconBox}>
                        <NotesIcon style={{color: "#2563EB"}}/>
                    </Box>
                    {file !== null && <Box style={{flexGrow:1, width:"50%"}}>
                        <Typography style={webStyle.fileNameStyle}>{fileData.name}</Typography>
                        <Typography style={webStyle.fileSizeStyle}>{fileData.size}</Typography>
                    </Box>}
                    <IconButton 
                        data-test-id={`fileMoreTestID${index}`}
                        aria-label="more1"
                        aria-haspopup="true"
                        id = {`fileArray${index}`}
                        disabled={!this.state.editModalOpen}
                        onClick={this.handleMoreClick}
                    >
                        <MoreVertIcon style={webStyle.fileMoreIcon} />
                    </IconButton>
                </Box>
                
            </CustomGrid>
        );
    };

    renderInputFieldBox = () => {
        const languageData = languageTranslationData[this.state.selectedLanguage];
        const fieldLabel = languageData.inputFields;
        const { errors, saveCheck, portfolioArray, clientName, website, regNumber, country, state, city, zipCode, bio, editModalOpen } = this.state;
        return (
          <Box style={editModalOpen? webStyle.EditInputFieldBox : webStyle.inputFieldBox}>
            <Grid container spacing={4}>
                {this.renderCustomGrid(fieldLabel.ClientName, clientName, fieldLabel.ClientName, errors.clientNameErr, "clientName")}
                {this.renderCustomGrid(fieldLabel.Website, website, fieldLabel.Website, errors.websiteErr, "website")}
                <CustomGrid item xs={12} sm={12} md={6}>
                    <Typography>{fieldLabel.IndustrySection}</Typography>
                    <FormControl fullWidth>
                        <CustomInputLabel shrink={false} style={{ display: !this.state.sector ? "block" : "none", lineHeight: "0.5" }}>
                            {fieldLabel.IndustrySection}
                        </CustomInputLabel>
                        <CustomSelect
                            fullWidth
                            disabled = {!editModalOpen}
                            data-test-id="sectorTestID"
                            IconComponent={KeyboardArrowDownRoundedIcon}
                            variant="outlined"
                            value={this.state.sector}
                            name="sector"
                            onChange={this.handleSectorChange}
                        >
                            <MenuItem value={"Financial Services"}>{fieldLabel.FinancialServices}</MenuItem>
                            <MenuItem value={"Financial Services"}>{fieldLabel.FinancialServices}</MenuItem>
                            <MenuItem value={"Financial Services"}>{fieldLabel.FinancialServices}</MenuItem>
                        </CustomSelect>
                    </FormControl>
                    {(errors.sectorErr && saveCheck) && (
                        <Typography style={webStyle.errorText}>
                            {errors.sectorErr}
                        </Typography>
                    )}
                </CustomGrid>
                {this.renderCustomGrid(fieldLabel.Regnumber, regNumber, fieldLabel.RegnumberPlaceholder, errors.regNumberErr, "regNumber")}
                {this.renderCustomGrid(fieldLabel.Country, country, fieldLabel.CountryPlace, errors.countryErr, "country")}
                {this.renderCustomGrid(fieldLabel.State, state, fieldLabel.StatePlace, errors.stateErr, "state")}
                {this.renderCustomGrid(fieldLabel.City, city, fieldLabel.CityPlace, errors.cityErr, "city")}
                {this.renderCustomGrid(fieldLabel.Zip, zipCode, fieldLabel.ZipPlace, errors.zipErr, "zipCode")}
                {this.renderServiceInput()}
                <CustomGrid item xs={12} sm={12} md={12}>
                    <Typography>{fieldLabel.Bio}</Typography>
                    <TextField
                        data-test-id="bioTestID"
                        disabled = {!editModalOpen}
                        value={bio}
                        name="bio"
                        multiline
                        size="small"
                        fullWidth
                        variant="outlined"
                        placeholder={fieldLabel.Bio}
                        minRows={4}
                        maxRows={6}
                        onChange={this.handleInputChange}
                    />
                    {(errors.bioErr && saveCheck) && (
                    <Typography style={webStyle.errorText}>
                        {errors.bioErr}
                    </Typography>
                    )}
                </CustomGrid>
                {portfolioArray.map((item, index) =>
                    (item===null && editModalOpen)
                    ? this.renderEmptyPortfolio(index)
                    : this.renderPortfolioFile(item, index)
                )}
                {editModalOpen && <CustomGrid
                    style={{ display: "flex", justifyContent: "center" }}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                >
                    <FileButtonBox 
                        style={{width:"224px"}} 
                        component={"label"}
                    >
                        <Box style={webStyle.createButtonInBox}>
                            <img 
                                src={fileUploadIcon}
                                style={{width:"24px"}}
                                alt="file_upload_icon"
                            /> 
                            {fieldLabel.Addmoredoc}
                        </Box>
                        <VisuallyHiddenInput type="file" data-test-id='addMoreTestID' onChange={(event)=>this.handleAddPortfolio(event, null)} />
                    </FileButtonBox>
                </CustomGrid>}
            </Grid>
          </Box>
        );
    };

    renderAvatarText = () => {
        const languageData = languageTranslationData[this.state.selectedLanguage];
        return (
            <Box style={webStyle.profileTextWrapper}>
                <Typography style={webStyle.profileTextStyle}>
                    {languageData.Logo}
                </Typography>
                <Typography style={webStyle.sizeTextStyle}>
                    {languageData.SizeShow}
                </Typography>
        </Box>
        )
    };

    renderEditModal = () => {
        const { editModalOpen, logo } = this.state;
        const languageData = languageTranslationData[this.state.selectedLanguage];
        return (
            <Modal
                data-test-id="editModalTestID"
                open={editModalOpen}
                onClose={this.handleEditModalClose}
                aria-labelledby="edit-modal"
                aria-describedby="modal_to_edit_business_profile"
            >
                <Box style={webStyle.modalBox}>
                    <Box style={webStyle.modalInnerBox}>
                        <Box style={webStyle.modalHeadingBox}>
                        <Typography style={{ fontSize: "24px", fontWeight: 700 }}>
                            {languageData.BusinessProfile}
                        </Typography>
                        <IconButton data-test-id="closeIconButtonTestId" onClick={this.handleEditModalClose}>
                            <CloseRoundedIcon
                                style={{ width: "24px", height: "24px", color: "#0F172A" }}
                            />
                        </IconButton>
                        </Box>
                        <ModalMiddleBox style={webStyle.modalMiddleBox}>
                            <Box style={webStyle.avatarTextWrapper}>
                                <Box style={{ position: "relative" }}>
                                <Badge
                                    data-test-id="avatarBadgeTestID"
                                    overlap="circular"
                                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                    badgeContent={
                                        <Box
                                            id="componyLogo"
                                            data-test-id="uploadLogoBoxTestID"
                                            style={webStyle.profileEditBox}
                                            onClick={logo?this.handleMoreClick:this.handleDivClick}
                                        >
                                            {logo? <EditIcon
                                                data-test-id="avatarEditTestID"
                                                style={webStyle.profilePicEditIcon}
                                            />:
                                            <img 
                                                src={fileUploadIcon}
                                                style={webStyle.profilePicEditIcon}
                                                alt="file_upload_icon"
                                            />}
                                        <VisuallyHiddenInput 
                                            ref={this.fileInputRef}
                                            type="file" 
                                            data-test-id='upload-btn' 
                                            onChange={this.handleLogoUpload} 
                                        />
                                        </Box>
                                    }
                                >
                                    <Avatar
                                        src = {this.handleLogoUrl()}
                                        data-test-id="avatar"
                                        alt="profile_image"
                                        style={webStyle.avatarStyle}
                                    />
                                </Badge>
                                </Box>
                                {this.renderAvatarText()}
                            </Box>
                            {this.renderInputFieldBox()}
                        </ModalMiddleBox>
                        <Box style={webStyle.modalBottomButtonBox}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <ResetButton 
                                        data-test-id="inputCancelButtonTestID"
                                        style={{width:"100%"}}
                                        onClick={this.handleInputCancel}
                                    >
                                        {languageData.Cancel}
                                    </ResetButton>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <SaveButton 
                                        data-test-id="inputSaveTestID"
                                        style={{width:"100%"}}
                                        onClick={this.handleInputSave}
                                    >
                                        {languageData.Save}
                                    </SaveButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        )
    };

    renderPrevInputUpp = () => {
        const { selectedLanguage, sector, regNumber, country, state, city, zipCode } = this.state;
        const inputFields = languageTranslationData[selectedLanguage].inputFields;
        return (
            <PrevInBox style={webStyle.prevInWrapper}>
                <Box >
                    <Typography style={webStyle.prevInUpValText}>{sector}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{inputFields.IndustrySection}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.prevInUpValText}>{regNumber}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{inputFields.Regnumber}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.prevInUpValText}>{country}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{inputFields.Country}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.prevInUpValText}>{state}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{inputFields.State}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.prevInUpValText}>{city}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{inputFields.City}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.prevInUpValText}>{zipCode}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{inputFields.Zip}</Typography>
                </Box>
            </PrevInBox>
        )
    };

    renderEditButtonBox = () => {
        const languageData = languageTranslationData[this.state.selectedLanguage];
        return (
            <Box style={{display:"flex", justifyContent:"flex-end", margin: "20px 0 18px"}}>
                <Box
                    data-test-id = "editButtonTestId"
                    style={webStyle.editWrapper}
                    onClick={() => this.handleEditModalOpen(true)}
                >
                    <Typography style={webStyle.editText}>{languageData.Edit}</Typography>
                    <EditIcon style={webStyle.editIcon} />
                </Box>
            </Box>
        )
    };

    renderPreview = () => {
        const { clientName, website, bio, selectedLanguage, portfolioArray, logo } = this.state;
        const languageData = languageTranslationData[selectedLanguage];
        const inputFields = languageData.inputFields;
        return (
            <Box data-test-id="previewTestID">
                <Typography style={webStyle.prevSubHead}>{languageData.prevSubheading}</Typography>
                {this.renderEditButtonBox()}
                <Box style={webStyle.prevBodyWrapper}>
                    <AvatarWrapperBox>
                        <Avatar
                            src = {this.handleLogoUrl()}
                            alt="logo"
                            style={{...webStyle.avatarStyle, width:"100px", height:"100px"}}
                        />
                        <Box style={webStyle.profileTextWrapper}>
                            <Typography style={{...webStyle.profileTextStyle, fontSize: "24px"}}>
                                {clientName}
                            </Typography>
                            <Typography style={{...webStyle.sizeTextStyle, fontSize:'16px', textDecoration:"underline"}}>
                                {website}
                            </Typography>
                        </Box>
                    </AvatarWrapperBox>
                    <CustomDivider/>
                    {this.renderPrevInputUpp()}
                    <CustomDivider/>
                    <Box>
                        <Typography style={webStyle.prevBioHeadingText}>{inputFields.Servicesoffered}</Typography>
                        <Box style={{display:"flex", gap:"10px", flexWrap:"wrap"}}>
                            {this.state.serviceArray.map((value, index) => (
                                <Box
                                    key={index}
                                    style={{...webStyle.serviceBox, paddingRight:"14px", margin:"0px"}}
                                >
                                    <Typography
                                        style={webStyle.seviceItemText}
                                    >
                                        {value}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <CustomDivider/>
                    <Box>
                        <Typography style={webStyle.prevBioHeadingText}>{inputFields.Bio}</Typography>
                        <Typography>{bio}</Typography>
                    </Box>
                    <CustomDivider/>
                    <Grid container spacing={4} xs={12} sm={12} md={12}>
                            {portfolioArray.map((item, index) =>
                                item===null
                                ? null
                                : this.renderPortfolioFile(item, index)
                            )}
                    </Grid>
                </Box>
                <Box style={{display:"flex", gap:"20px", marginTop: "45px"}}>
                    <SaveButton 
                        style={{width:"200px"}}
                        onClick={this.handlePrevConfirmSave}
                        data-test-id="prevConfirmTestID"
                    >
                        {languageData.confirmSaving}
                    </SaveButton>
                    <ResetButton 
                        style={{width:"200px"}}
                        onClick={this.handleInputCancel}
                    >
                        {languageData.Cancel}
                    </ResetButton>
                </Box>
            </Box>
        );
    };

    renderBusinessProfileData = () => {
        const { selectedLanguage, logo } = this.state;
        const languageData = languageTranslationData[selectedLanguage];
        return (
            <Box style={{paddingTop:"1px"}}>
                {this.renderEditButtonBox()}
                <Box style={webStyle.busProfDataWrapper}>
                    <Box style={webStyle.avatarTextWrapper}>
                        <Avatar
                            src = {this.handleLogoUrl()}
                            data-test-id="avatar"
                            alt="profile_image"
                            style={webStyle.avatarStyle}
                        />
                        {this.renderAvatarText()}
                    </Box>
                    {this.renderInputFieldBox()}
                </Box>
                <Box data-test-id="businessDeleteTestID" style={webStyle.deleteBox} onClick={this.handleDeleteModalOpen}>
                    <DeleteOutlineRoundedIcon style={webStyle.deleteIcon} />
                    <Typography style={webStyle.deleteText}>{languageData.DeleteBusinessProfile}</Typography>
                </Box>
            </Box>
        )
    };

    renderDeleteModal = () => {
        const { selectedLanguage } = this.state;
        const languageData = languageTranslationData[selectedLanguage];
        const dir = selectedLanguage === "ar"? "rtl": "ltr";
        return (
          <Modal 
            data-test-id = "deleteModalTestId"
            open={this.state.showModal}
            onClose={this.handleDeleteModalClose}
          >
            <Box style={webStyle.modalMainBox}>
              <Box style={webStyle.modalBodyBox}>
                <Box dir={dir} style={webStyle.modHeadingBox}>
                  <Typography style={webStyle.modHeading}>
                    {languageData.deleteConfirmText}
                  </Typography>
                  <IconButton onClick={this.handleDeleteModalClose}>
                    <CloseRoundedIcon style={{color: "#0F172A"}}/>
                  </IconButton>
                </Box>
                <Box style={webStyle.modButtonBox}>
                    
                  <ResetButton style={{width: "100%", height: "56px"}} onClick={this.handleDeleteModalClose}>{languageData.Cancel}</ResetButton>
                  <SaveButton 
                    data-test-id="modalDeleteButtonTestID"
                    style={{width: "100%", height: "56px"}}
                    onClick={this.deletBusinessProfile}
                  >
                    {languageData.DeleteBusinessProfile}
                  </SaveButton>
                </Box>
              </Box>
            </Box>
          </Modal>
        );
      };

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { isDataAvailable, selectedLanguage, showPreview } = this.state;
        const languageData = languageTranslationData[selectedLanguage];
        // Customizable Area End
        return (
            // Customizable Area Start
            <UserProfileDrawer 
                data-test-id="drawerComponentTestID" 
                handleClick={this.handleDrawerBreadscrumClick}
                breadScrumArray={this.state.breadscrumArray}
                pageName="BusinessProfile"
            >
                <Box>
                    <Box style = { webStyle.pageHeadingBox} >
                        <Typography style={webStyle.pageHeading}>
                            {this.state.pageHeading}
                        </Typography>
                    </Box>
                    {(!isDataAvailable && !showPreview) && this.renderBlank()}
                    {showPreview && this.renderPreview()}
                    {(isDataAvailable && !showPreview) && this.renderBusinessProfileData()}
                    {this.renderEditModal()}
                </Box>
                {this.state.anchorEl && <Menu
                        id="long-menu"
                        data-test-id="menuTestID"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={this.state.anchorEl}
                        getContentAnchorEl={null}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleCloseMenu}
                        PaperProps={{
                            style: {
                                width: '198px',
                                borderRadius: "8px",
                                border: "1px solid #E2E8F0",
                                background: "#FFF",
                                padding: "0px 8px",
                                boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
                            },
                        }}
                    >
                        <MenuButton component="label">
                            <img src={fileUploadIcon} alt="upload" style={{ marginRight: "10px" }} />
                            { (this.state.anchorEl.id === "componyLogo")?
                                languageData.UploadPhoto: 
                                languageData.UploadDocument
                            }
                            <VisuallyHiddenInput type="file" data-test-id='menuuploadbtn' onChange={this.handleFileChange} />
                        </MenuButton>
                        <MenuButtonDelete onClick={this.handleFileRemove} data-test-id='removeBtnTestID' component="label">
                            <DeleteOutlineRoundedIcon id="modalID" style={{ width: "18px" }} />
                                { (this.state.anchorEl.id === "componyLogo")?
                                    languageData.RemovePhoto:
                                    languageData.RemoveDocument
                                }
                        </MenuButtonDelete>
                    </Menu>}
                    {this.renderDeleteModal()}
            </UserProfileDrawer>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const AvatarWrapperBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "16px",
    marginBottom: "20px",
    flexWrap: "wrap"
});

const ModalMiddleBox = styled(Box)({
    "@media(max-width: 959px)":{
        marginBottom: "169px !important"
    }
})

const PrevInBox = styled(Box)({
    justifyContent: "space-between",
    "@media(max-width: 1200px)":{
        justifyContent: "flex-start"
    }
});

const CustomDivider = styled(Divider)({
    margin:"25px 0 45px",
    background: "#E2E8F0",
    display: "block !important"
});

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'relative',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
});

const MenuButton = styled(Box)({
    borderRadius: '8px',
    cursor: "pointer",
    background: '#EFF6FF',
    display: 'flex',
    textTransform: 'capitalize',
    padding: '7px 16px',
    alignItems: 'center',
    flexShrink: 0,
    alignSelf: 'stretch',
    transition: 'background-color 0.3s',
    whiteSpace: "nowrap",
    '&:hover': {
        backgroundColor: "#EFF6FF"
    }
});

const MenuButtonDelete = styled(Box)({
    borderRadius: '8px',
    cursor: "pointer",
    background: '#FFF',
    display: 'flex',
    textTransform: 'capitalize',
    padding: '7px 16px',
    alignItems: 'center',
    marginTop: "10px",
    gap: '8px',
    flexShrink: 0,
    alignSelf: 'stretch',
    transition: 'background-color 0.3s',
    whiteSpace: "nowrap",
    color:"#DC2626",
    '&:hover': {
        background: '#FEFEFE'
    }
});

const ServiceTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "8px",
        border: "none !important"
      }
    },
    "& .MuiInputBase-input": {
      height: "30px",
      color: "#0F172A",
      fontSize: "14px",
      fontWeight: "400",
      "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A"
      }
    },
    "& .MuiInputBase-input::placeholder": {
      fontSize: "16px",
      color: "#94A3B8",
      opacity: 1
    }
  });

const PortfolioBodyBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
});

const EmptyUploadIcon = styled("img")({
    filter: "invert(62%) sepia(23%) saturate(452%) hue-rotate(165deg) brightness(97%) contrast(50%)",
    width: "25px",
    height: "25px"
});

const ResetButton = styled(Button)({
    backgroundColor: "#EFF6FF",
    height: "56px",
    color: "#0F172A",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "#EFF6FF"
    }
});

const FileButtonBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    backgroundColor: "#EFF6FF",
    justifyContent: "center",
    cursor: "pointer",
    height: "44px",
    color: "#0F172A",
    borderRadius: "8px",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 700
});

const CustomGrid = styled(Grid)({
    "& .MuiTypography-root": {
      color: "#334155",
      fontSize: "14px",
      fontWeight: 700,
      marginBottom: "8px"
    },
    "& .MuiTextField-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "8px",
          border: "1px solid #CBD5E1"
        }
      },
      "& .MuiInputBase-input": {
        height: "30px",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: "400",
        "&.Mui-disabled": {
          WebkitTextFillColor: "#0F172A"
        }
      },
      "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
      }
    }
});

const CustomSelect = styled(Select)({
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 400,
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "1px solid #CBD5E1"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD5E1"
      
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A",
        borderColor: "#CBD5E1",
        "& fieldset": {
            borderColor: "#CBD5E1 !important"
        }
    },
    "& .MuiSelect-outlined": {
        background: "transparent !important"
    }
});

const CustomInputLabel = styled(InputLabel)({
    fontSize: "16px",
    color: "#94A3B8",
    opacity: 1,
    paddingLeft: "14px",
    "&.Mui-focused": {
      display: "none",
      color: "#94A3B8"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A"
    }
});

const WebsiteTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "8px",
          border: "1px solid #CBD5E1"
        }
      },
      "& .MuiInputBase-input": {
        height: "30px",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: "400",
        "&.Mui-disabled": {
          WebkitTextFillColor: "#0F172A"
        }
      },
      "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1,
        textDecoration: "none"
      }
});

const SaveButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "white",
    height: "56px",
    backgroundColor: "#1E3A8A",
    borderRadius: "8px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#1E3A8A"
    }
});

const webStyle = {
    deleteBox: {
        display:"flex",
        gap: "5px",
        cursor: "pointer",
        marginTop: "40px",
        width: "260px"
    },
    deleteIcon: {
        color: "#DC2626",
        width: "24px"
    },
    deleteText: {
        marginTop: "2px",
        color: "#DC2626",
        fontWeight: 700,
        fontSize: "18px"
    },
    prevBioHeadingText: {
        fontSize: "20px",
        fontWeight: 600,
        marginBottom: "25px"
    },
    prevBodyWrapper:{
        padding:"40px",
        borderRadius: "24px",
        boxShadow: "0px 0px 8px 4px #00000008"
    },
    busProfDataWrapper: {
        padding:"40px 0",
        borderRadius: "24px",
        boxShadow: "0px 0px 8px 4px #00000008"
    },
    prevInUpValText: {
        color: "#0F172A",
        fontWeight: 600,
        fontSize: "18px",
        marginBottom: "10px"
    },
    prevInWrapper:{
        display:"flex",
        gap:"30px",
        flexWrap: "wrap"
    } as React.CSSProperties,
    prevSubHead: {
        color: "#0F172A",
        fontSize: "16px"
    },
    editWrapper: {
        display: "flex",
        gap: "8px",
        cursor: "pointer",
        alignItems: "center"
    },
    editText: {
        fontSize: "16px",
        fontWeight: 700,
        color: "#1E3A8A"
    },
    editIcon: {
        color: "#1E3A8A",
        width: "15px"
    },
    seviceItemText: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#334155",
        margin: "0px"
    },
    serviceBox: {
        display: "flex",
        background: "#E2E8F0",
        height: "30px",
        borderRadius: "25px",
        alignItems: "center",
        padding: "4px 2px 4px 14px",
        margin:"5px"
    },
    busiServiceBox: {
        display: "flex",
        background: "#E2E8F0",
        height: "30px",
        borderRadius: "25px",
        alignItems: "center",
        padding: "4px 14px",
        margin:"0px"
    },
    serviceWrapper:{
        display: "flex",
        flexWrap: "wrap",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        gap: "4px",
        alignItems:"center"
    } as React.CSSProperties,
    portFolioBodyText:{
        color: "#64748B",
        fontSize: "12px",
        fontWeight: 400,
        margin: "0px"
    },
    fileMoreIcon: {
        color: "#475569",
        cursor: "pointer"
    },
    fileSizeStyle: {
        color: "#64748B",
        fontWeight: 400,
        fontSize: "16px",
        marginBottom: "0",
    },
    fileNameStyle: {
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 400,
        marginBottom: "0",
        width:"100%",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    fileInputWrapper: {
        display: "flex",
        alignItems: "center",
        padding: "8px",
        boxShadow: "0px 0px 8px 4px #00000008",
        borderRadius: "8px",
        gap: "14px"
    },
    noteIconBox: {
        backgroundColor: "#DBEAFE",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "56px",
        minWidth: "56px",
        height: "56px",
        cursor: "pointer"
    },
    upPortText: {
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 700,
        margin: "0"
      },
      porfolioBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px dashed #CBD5E1",
        borderRadius: "8px",
        padding: "18px",
        gap: "6px"
      } as React.CSSProperties,
    blankParagraphText:{
        textAlign: 'center',
        fontSize: "18px",
        fontWeight: 500
    } as React.CSSProperties,
    blankWrapper:{
        display:"flex",
        justifyContent:"center",
        alignItems:'center',
        height:"565px"
    },
    blankBodyBox: {
        display:'flex',
        flexDirection:"column",
        gap:"30px",
        alignItems:"center"
    } as React.CSSProperties,
    pageHeadingBox: {
        marginBottom: "16px"
    },
    modalMainBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
    },
    modalBodyBox: {
        background: "white",
        borderRadius: "20px",
        boxShadow: "0px 0px 8px 4px #00000008",
        width: "560px"
    },
    modHeadingBox: {
        display: "flex",
        justifyContent: "space-between",
        padding: "20px 17px 20px 35px",
        borderBottom: "1px solid #E2E8F0",
        alignItems: "baseline"
    },
    modHeading: {
        fontSize: "20px",
        color: "#0F172A",
        fontWeight: 700
    },
    modButtonBox: {
        display: "flex",
        gap: "20px",
        padding: "25px"
    },
    pageHeading: {
        color: "#0F172A",
        fontSize: "30px",
        fontWeight: 700 
    },
    createButtonInBox: {
        display:'flex',
        gap:"10px"
    },
    modalBottomButtonBox: {
        position: "absolute",
        bottom: "0",
        background: "white",
        width: "100%",
        padding: "24px 40px",
        boxShadow: "0px 25px 50px 0px #00000017",
        boxSizing: "border-box"
      } as React.CSSProperties,
      errorText: {
        fontSize: "12px",
        fontWeight: 400,
        color: "red",
        margin: "5px 0 0 0",
      },
      EditInputFieldBox: {
        borderBottom: "1px solid #E2E8F0",
        padding: "0 40px 20px",
        marginBottom: "35px"
      },
      inputFieldBox: {
        padding: "0 40px 10px"
      },
      sizeTextStyle: {
        color: "#334155",
        fontSize: "14px",
        fontSeight: 400
      },
      profileTextStyle: {
        color: "#0F172A",
        fontSize: "18px",
        fontWeight: 600
      },
      profileTextWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      } as React.CSSProperties,
      avatarStyle: {
        width: "80px",
        height: "80px",
        border: "2px solid #DBEAFE"
      },
      profilePicEditIcon: {
        color: "#0F172A",
        width: "16px",
        height: "16px"
      },
      profileEditBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "32px",
        height: "32px",
        borderRadius: "8px",
        backgroundColor: "#EFF6FF",
        cursor: "pointer"
      },
      modalBox: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        paddingTop: "40px",
        border: "none"
      },
      modalInnerBox: {
        backgroundColor: "#FFFFFF",
        borderRadius: "24px",
        boxShadow: "0px 0px 8px 4px #00000008",
        height: "90vh",
        width: "100%",
        maxWidth: "850px",
        position: "relative",
        overflow: "hidden"
      } as React.CSSProperties,
      modalHeadingBox: {
        padding: "25px 40px 10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E2E8F0",
        position: 'relative',
        zIndex:2,
        background:"white"
      } as React.CSSProperties,
      modalMiddleBox: {
        padding: "180px 0px 8px",
        height: "inherit",
        boxSizing: "border-box",
        overflowY: "scroll",
        marginBottom:"89px",
        position: "absolute",
        bottom: 0
      } as React.CSSProperties,
      avatarTextWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
        margin: "0 40px 20px",
        flexWrap: "wrap"
      } as React.CSSProperties
}
// Customizable Area End
