
import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area Start
import {
  Container,
  Typography,
  Grid,
  Box,
  styled,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  List,
  Paper
} from "@material-ui/core";
import ImageTextCard from "../../../components/src/design-system/Card/ImageTextCard";
import { Add } from "@material-ui/icons";
const configJSON = require("./config");
import DiscoverCard from "../../../components/src/design-system/Card/DiscoverCard";

// Customizable Area End
import DebtBuyerController, { Props } from './DebtBuyerController.web'
import Loader from "../../../components/src/Loader.web";
import parse from 'html-react-parser';
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

type TextAlign = "left" | "right" | "center" | "justify";

const headingParts = configJSON.debtByuercard1head.split('Comprehensive Guide');
// Customizable Area End
export default class DebtBuyer extends DebtBuyerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    const { currentLanguage, loading } = this.state;
    const landingSection = this.state.userDataList[0];
    const secondSection = this.state.userDataList[1];
    const thirdSection = this.state.userDataList[2];
    const fourthSection = this.state.userDataList[3];
    const isEnglish = currentLanguage === 'en';
    const descriptionKey = isEnglish ? 'description' : "arabic_description";
    const titleKey = isEnglish ? "title" : "arabic_title"
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Loader loading={loading} />
        <Container style={{ padding: '40px 0px', maxWidth: '100%' }}>
          <StyledSecondaryWrapper>
          {<SectionMain maxWidth={false} style={webStyle.section1}>
            {<GridBox container spacing={2} style={webStyle.firstRow}>
              <TopLeftGridBox item xs={12} sm={7} lg={7} justifyContent="center" direction="column" style={webStyle.sectionOne}>
                <Typography variant="h4" className="sectionOneTitle">
                {!isEnglish? this.state.bannerData.attributes.banner_title_arabic : this.state.bannerData.attributes.banner_title} <span style={webStyle.highlightText}>{this.state.bannerData.attributes.sub_text1}</span> {this.state.bannerData.attributes.sub_text2}
                </Typography>
                <Typography variant="body1" style={webStyle.sectionOneDetail}>
                  {parse(`${!isEnglish? this.state.bannerData.attributes.banner_description_arabic :this.state.bannerData.attributes.banner_description}`)}
                </Typography>
              </TopLeftGridBox>
              <BannerImage item xs={12} sm={5} lg={5}>
                <img className="img-banner" style={webStyle.imageOneimage} src={this.state.bannerData.attributes.image} alt="Frame" />
              </BannerImage>
            </GridBox>}
          </SectionMain>
        }
          
          {this.state.userDataList.slice(0 , 1).map((card, cIndex: number) => (
            <CardBox key={card.id || cIndex}>
              <ImageTextCard
                topHeading={card.attributes.step_text}
                heading={!isEnglish? card.attributes.arabic_title : card.attributes.title}
                description={!isEnglish? card.attributes.arabic_description : card.attributes.description}
                image={card.attributes.image}
                darkTheme={false}
                alignLeft={cIndex % 2 !== 1}
              />
            </CardBox>
          ))}

          </StyledSecondaryWrapper>
        </Container>

        <StyledBox>
          <StyledContainerB>
            <StyledTitle>
              {configJSON.dbdtcarshead}
            </StyledTitle>
            <Box>
              {this.state.userDataList.map((card, cIndex: number) => (
                <Box key={card.id || cIndex}>
                  {Array.isArray(card.attributes.sub_debut_buyers) && card.attributes.sub_debut_buyers.length > 0 && (
                    <Grid container spacing={3}>
                      {card.attributes.sub_debut_buyers.map((subBuyer, subIndex: number) => (
                        <Grid item xs={12} md={4} key={card.id || cIndex}>
                          <StyledPaper key={subIndex} elevation={3}>
                            <PaperTitle gutterBottom>
                              {!isEnglish? subBuyer.arabic_title : subBuyer.title}
                            </PaperTitle>
                            <PaperText>
                              {!isEnglish? subBuyer.arabic_description : subBuyer.description}
                            </PaperText>
                          </StyledPaper>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Box>
              ))}
            </Box>
          </StyledContainerB>
        </StyledBox>

        <Container style={{ padding: '0px 0px 40px 0px', maxWidth: '100%' }}>
          {this.state.userDataList.slice(1).map((card, cIndex: number) => (
            <CardBox key={card.id || cIndex}>
              <ImageTextCard
                topHeading={card.attributes.step_text}
                heading={!isEnglish? card.attributes.arabic_title : card.attributes.title}
                description={!isEnglish? card.attributes.arabic_description : card.attributes.description}
                image={card.attributes.image}
                darkTheme={false}
                alignLeft={cIndex % 2 !== 1}
              />
            </CardBox>
          ))}
        </Container>
        <MainWrapper>
          <StyledContainer maxWidth="md">
            <Box mb={4}>
              <BlueTypography variant="body1">{configJSON.faqsubmit}</BlueTypography>
              <Typography variant="h4" align="center" className="faqHeader">
                {configJSON.faqhead}
              </Typography>
              <Typography variant="body1" align="center" className="faqsubHeader">
                {configJSON.debtBuyerfaqsubHead}
              </Typography>
            </Box>
            {this.state.dataFAQ.map((faqdata, index) => (
              <StyledList key={index}>
                <StyledAccordion>
                  <AccordionSummary expandIcon={<Add />}>
                    <Typography className="question">{faqdata.attributes.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="answer">{faqdata.attributes.answer}</Typography>
                  </AccordionDetails>
                </StyledAccordion>
              </StyledList>
            ))}
          </StyledContainer>
        </MainWrapper>

      <DiscoverCard/>
      </ThemeProvider>

      // Customizable Area End

    );
  }
}
// Customizable Area Start
const GridBox = styled(Grid)({
  '& .sectionOneTitle': {
    color: '#000',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '52px',
    fontSize: '36px',
    marginBottom: '24px',

    '@media (max-width: 1200px)': {
      fontSize: '25px',
      lineHeight: '30.205px',
    }
  },
  '& .img-banner': {
    '@media (max-width: 1200px)': {
      width: '80%',
    }
  }
})
const SectionMain = styled(Container)({
  padding: '90px 40px',
  backgroundSize: "auto 100%",
  "@media (max-width: 960px)": {
    padding: "40px 20px"
  },
  "@media (max-width: 600px)": {
    padding: "40px 20px",
  },
  "@media (min-width: 1440px)": {
    backgroundSize: "auto 115%",
  },
  '& .MuiInputBase-input-root': {
    padding: 12
  },
  '& .MuiInputBase-input:focus' : {
    outline: 'solid'
  }
});
const TopLeftGridBox = styled(Grid)({
  '@media (max-width: 840px)': {
    flexBasis: "100%",
    maxWidth: "100%"
  }
});
const BannerImage = styled(Grid)({
  alignItems: "flex-start",
  display: "flex",
  justifyContent: "center",
  "@media (max-width: 960px)": {
    alignItems: "center",
  },
  '@media (max-width: 840px)': {
    maxWidth: "100%",
    flexBasis: "100%",
  },
  "@media (max-width: 600px)": {
    alignItems: "center",
  },
})
const StyledContainer = styled(Container)({
  "& .faqHeader": {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "44px",
    letterSpacing: "-0.005em",
    textAlign: "center",
    marginBottom: "20px",
  },
  "& .faqsubHeader": {
    color: "#64748B",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "center",
    marginBottom: "20px",
  },
});

const BlueTypography = styled(Typography)({
  color: '#2563EB',
  textAlign: "center",

});

const StyledList = styled(List)({
  "& .MuiAccordion-root": {
    borderRadius: "8px",
    marginBottom: "10px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    "&:before": {
      display: "none",
    },
  },
  "& .MuiAccordionSummary-root": {
    backgroundColor: "#F8FAFC",
    borderRadius: "8px",
    "&.Mui-expanded": {
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
    },
  },
  "& .MuiAccordionSummary-content": {
    margin: "12px 0",
  },
  "& .question": {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "28px",
    paragraph: '12px',
  },
  "& .answer": {
    color: "#161616",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    paragraph: '8px',
  },
});

const StyledBox = styled(Box)({
  backgroundColor: '#EFF6FF',
});

const StyledContainerB = styled(Container)({
  padding: '80px 120px',
  display: 'flex',
  flexDirection: 'column',
  gap: '44px',
  '@media(max-width:991px)': {
    padding: '40px'
  },
  '@media(max-width:576px)': {
    padding: "40px 20px",
  }
});

const StyledTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: '30px',
  textAlign: 'center',
  '@media(max-width:991px)': {
    fontSize: '24px',
  },
  '@media(max-width:576px)': {
    fontSize: '24px',

  }
});

const StyledPaper = styled(Paper)({
  padding: '40px',
  borderRadius: '24px',
  textAlign: 'center',
});

const PaperTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: '20px',
  LineHeight: '32px',
  Letter: '-0.5%',
  whiteSpace: 'nowrap',

});

const PaperText = styled(Typography)({
  fontWeight: 400,
  fontSize: '14px',
  color: '#475569',
});


const MainWrapper = styled(Box) ({
  backgroundColor: "#F8FAFC",
  padding: "80px 120px 80px 120px",
  '@media(max-width:991px)': {
    padding: '40px'
  },
  '@media(max-width:576px)': {
    padding: "40px 20px",
  }
});

const StyledAccordion = styled(Accordion) ({
  border: "1px solid #E5E7EB",
  "&:not(:last-child)": {
    borderBottom: "none",
  },
  "&:before": {
    display: "none",
  },
  "& .MuiAccordionSummary-root": {
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    minHeight: "56px",
    "&.Mui-expanded": {
      minHeight: "56px",
    },
  },
  "& .MuiAccordionSummary-content": {
    margin: "12px 0",
    "&.Mui-expanded": {
      margin: "12px 0",
    },
  },
  "& .MuiAccordionDetails-root": {
    padding: "16px",
  },
});
const CardBox = styled(Box)({
  "@media (max-width: 1280px)": {
    paddingRight: "40px",
    paddingLeft: "40px",
  },
  "@media (max-width: 960px)": {
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  "@media (max-width: 767px)": {
    paddingRight: "0px",
    paddingLeft: "0px",
  },
  "& .MuiGrid-root .MuiGrid-item": {
    "@media (max-width: 960px)": {
      maxWidth: "50%",
      flexBasis: "50%",
    },
    "@media (max-width: 840px)": {
      maxWidth: "100%",
      flexBasis: "100%",
      "& .MuiPaper-root": {
        textAlign: "center"
      }
    }
  },
  "& .MuiGrid-root": {
    "&[id='gridRightToLeft']": {
      "@media (max-width: 991px)": {
        flexDirection: "row-reverse"
      },
      "@media (max-width: 840px)": {
        flexDirection: "column"
      }
    }
  }
});
const StyledSecondaryWrapper = styled(Box) ({
  padding: " 0 ",
  '@media(max-width:1200px)': {
    padding: "0 ",
    
  },
  '@media(max-width:991px)': {
    flexDirection: 'column-reverse'
  },
  '@media(max-width:576px)': {
    padding: "64px 0px",
  }
});



const webStyle = {
  sectionOne: {
    display: "flex",
  },
  sectionContain: {
    justifyContent: "center",
    maxWidth: "none",
    background: "#334155",
  },
  imageOne: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  button: {
    marginTop: "20px",
    width: "max-content",
  },
  sectionOneDetail: {
    color: "#0F172A",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    width: '100%',
    lineHeight: "26px"
  },
  highlightText: {
    backgroundColor: "#DBEAFE", 
    color: "#1D4ED8", 
    borderRadius: 16, 
    padding: "2px 5px",
    whiteSpace: "nowrap"
  } as React.CSSProperties,
  section1: {
    height: "auto",
    justifyContent: "center",
    maxWidth: "none",
    alignItems: 'center',
    display: 'flex',
  },
  imageCenter: {
    width: '70vw',
    maxWidth: "488px",
    textAlign: "center" as TextAlign,
  },
  section2: {
    marginTop: "67px",
    marginBottom: "64px",
    justifyContent: "center",
    maxWidth: "none",
    background: "#f0f4f8",
  },
  section3: {
    padding: 0,
    paddingTop: 60,
    paddingBottom: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  section4: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  firstRow: {
    maxWidth: "1200px",
    margin: "0px auto",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  cards: {
    width: '100%',
    background: '#EFF6FF',
    padding: '80px 120px 80px 120px',

  },
  cardHead: {
    textAlign: 'center',

  },
  imageOneimage: {
    width: "90%",
    maxWidth: "488px"
  },
};
// Customizable Area End
