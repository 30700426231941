Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.putMethod = "PUT";
exports.putMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "adminconsole2";
exports.labelBodyText = "adminconsole2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.adminApiEndPoint = "bx_block_profile/admin_profiles/show_profile";
exports.getProfile = "account_block/accounts/";
exports.getBusinessProfile = "account_block/business_profiles";
exports.getSubscriptions = "bx_block_custom_user_subs/subscriptions";
exports.unarchiveEndPoint = "bx_block_admin/user_lists/2013/unarchived_user"
exports.navTitle = "Jeddah House of Commerce";
exports.navAdmin = "ADMIN";
exports.fetchUsersEndPoint = "account_block/accounts";
exports.navADMIN = "Admin";
exports.verificationBtn = "Verification Requests";
exports.verificationApprove = "Approved";
exports.verificationApprovenum = "14";
exports.verificationReject = "Rejected";
exports.verificationRejectnum = "5";
exports.verificationPending = "Pending";
exports.verificationPendingnum = "12";
exports.filterBtn = "Filters";
exports.requestId = "Request ID";
exports.name = "Name";
exports.rNumber = "Registration number";
exports.date = "Date";
exports.verificationMenutext1 = "View Details";
exports.verificationMenutext2 = "Approve";
exports.verificationMenutext3 = "Reject";
exports.accordianHeadText1 = "United States";
exports.accordianHeadText2 = "Florida";
exports.accordianHeadText3 = "Miami";
exports.accordianHeadText4 = "33101";
exports.accordianHeadText5 = "(567) 890-1234";
exports.accordianSmallText1 = "Country";
exports.accordianSmallText2 = "State";
exports.accordianSmallText3 = "City";
exports.accordianSmallText4 = "Zip";
exports.accordianSmallText5 = "Phone Number";
exports.companyData =[
  {
    HeadText:"United States",
    smallText: "Country"
  },
  {
    HeadText:"Florida",
    smallText: "State"
  },
  {
    HeadText:"Miami",
    smallText: "City"
  },
  {
    HeadText:"33101",
    smallText: "Zip"
  },
  {
    HeadText:"(567) 890-1234",
    smallText: "Phone Number"
  }

]
exports.businessProfileData =[
  {
    TopText:"Financial Services",
    BottomText: "Industry/Sector Section"
  },
  {
    TopText:"CRN-456789123",
    BottomText: "Registration number"
  },
  {
    TopText:"United States",
    BottomText: "Country"
  },
  {
    TopText:"Florida",
    BottomText: "State"
  },
  {
    TopText:"Miami",
    BottomText: "City"
  },
  {
    TopText:"33101",
    BottomText: "Zip"
  },
  {
    TopText:"(567) 890-1234",
    BottomText: "Phone Number"
  }

]
exports.accordianSecondBoxHead = "Services offered";
exports.accordianSecondBoxWrapper1 = "Financial Consultation";
exports.accordianSecondBoxWrapper2 = "Debt Collection Services";
exports.accordianSecondBoxWrapper3 = "Business Valuation";
exports.accordianSecondBoxWrapper4 = "Risk Management";
exports.accordianSecondBoxWrapper5 = "Market Research and Analysis";
exports.accordianSecondBoxHeadText = "Services offered";
exports.accordianUserBoxHeadText = "Detailed Information";
exports.accordianThirdBoxHead = "Bio";
exports.accordianThirdBoxDescription = "Apex Financial Solutions is a leading provider of comprehensive financial services, specializing in debt collection, financial consultation, and risk management. With over 20 years of industry experience, our mission is to help businesses and individuals navigate their financial challenges and achieve sustainable growth.";
exports.documentBoxPortfolit = "Portfolio";
exports.documentBoxFileName = "File name.doc";
exports.documentBoxFileSize = "128KB";
exports.VrFirstHeadNumber = "#669059";
exports.avtarHeading = "Apex Financial Solutions";
exports.avtarEmail = "www.apexfinancialsolutions.com";
exports.acordianFirstMainBoxTextHead = "Financial Services";
exports.acordianFirstMainBoxTextDes = "Industry/Sector Section";
exports.acordianFirstMainBoxTextHead1 = "CRN-456789123";
exports.acordianFirstMainBoxTextDes1 = "Registration number";
exports.acordianMainBoxHeading = "Company information";
exports.acordianUserProfile = "User information";
exports.acordianUserName = "James Carter";
exports.acordianUserEmail = "james.carter@example.com";
exports.accordianUserBirthDate1 = "January 15, 1980"
exports.accordianUserBirthTitle = "Date of Birth"
exports.accordianUserPhone = "987-65-4321"
exports.accordianUserPhoneLabel = "National ID or Social Security Number"
exports.documentUserFilelabel1 = "ID Proof (e.g., Driver’s License, Passport)";
exports.documentUserFilelabel2 = "Address Proof ";
exports.accordianUserBoxheading = " Debt Portfolio Information";
exports.dolorWrapperHead = "$2,500";
exports.dolorWrapperDes = "Portfolio Size";
exports.UserWrapperHead = "1";
exports.UserWrapperDes = "Account Count";
exports.ServerWrapperHead = "$500";
exports.ServerrWrapperDes = "Average Balance per Account";
exports.accordianUserCardname = "Credit Card"
exports.accordianUserCardType = "Type of Debt"
exports.accordianUserCardValidity = "1 years"
exports.accordianUserCardValidLabel = "Age of Debt"
exports.varificationHeadingText = "Verification Timeline"
exports.varificationTimelineDotText = "1"
exports.varificationTimelineContent = "22 Feb 2022"
exports.varificationTimelineContentText = " Initial request submitted by user"
exports.bottomBoxHeading = "Administrator's decision"
exports.bottomSmallText = "Business risk"
exports.varificationMenuItem1 = "High Risk"
exports.varificationMenuItem2 = "Medium Risk"
exports.varificationMenuItem3 = "Low Risk"
exports.approveButtonText = "Approve"
exports.rejectButtonText = "Reject"
exports.uploadFileSectionHeading = "Documentation"
exports.uploadFileSectionGridText = "Document"
exports.uploadImageLable = "Upload document"
exports.uploadImageFiletype = "Upload your document. Supports format: JPG, PNG, PDF,"
exports.choseFileButtonText = "Choose a File"
exports.documentButtonText = "Add more document"
exports.premiumButtonText = "Premium verification"
exports.requestVR = "Verification Requests"
exports.requestVR2 = "Verification Request Details"
exports.tabAccountDetails = "Account Details"
exports.tabBusinessProfile = "Business Profile"
exports.name = "Name";
exports.rNumber = "Registration number";
exports.date = "Date";
exports.status = "Status";
exports.formSearch = "Search";
exports.filterBtn = "Filters";
exports.userProfile = "User Management";
exports.userProfileDetails = "User profile detail";
exports.accountDetailsActive = "Active Collection";
exports.accountDetailsLogin = "Last login";
exports.accountDetailsLoginDate = "3 Feb 2024";
exports.accountDetailsCreate = "Creation Date";
exports.deleteProfile = "Delete profile";
exports.profiledescriptionText = "Confirm if you want to Delete the Profile.";
exports.requestVR2 = "Verification Request Details";
exports.commonLanguageData = {
  "en": {
    "Search": "Search",
    "Filters": "Filters",
    "Reset": "Reset",
    "From": "From",
    "To": "To",
    "Status": "Status",
    "Accepted": "Accepted",
    "Rejected": "Rejected",
    "Applynow": "Apply now",
    "ViewDetails": "View Details",
    "Delete": "Delete",
    "of": "of",
    "results": "results",
    "Confirm": "Confirm",
    "Cancel": "Cancel",
    "Edit": "Edit",
    "Home": "Home",
    "Seeall": "See all"
  },
  "ar": {
    "Search": "بحث",
    "Filters": "تصفيه",
    "Reset": "إعادة تعيين",
    "From": "من",
    "To": "إلى",
    "Status": "الحالة",
    "Accepted": "مقبول",
    "Rejected": "مرفوض",
    "Applynow": "تقدم الآن",
    "ViewDetails": "عرض التفاصيل",
    "Delete": "حذف",
    "of": "من",
    "results": "نتيجة",
    "Confirm": "تأكيد",
    "Cancel": "ألغى",
    "Edit": "يحرر",
    "Home": "الرئيسية",
    "Seeall": "مشاهدة الكل"
  }
};
exports.dummyOfferDescription = "Our firm, Apex Financial Solutions, has over 15 years of experience in debt collection and management. We have successfully handled similar debt portfolios and have a proven track record of high recovery rates. Our team’s expertise ensures that we can manage this debt efficiently and effectively, maximizing returns for your business.";
exports.replaceAlphabetRegex = /\D/g;
exports.varificationRequestEndPoint = "account_block/request_verifications/list_verification_admin/"
exports.varificationRequestShowEndPoint = "account_block/request_verifications/"
exports.deleteAccountEndpoint = "account_block/accounts/"
exports.deleteMethod = "DELETE"
exports.verificationStatus = [
  "select", "verification", "detailed_information", "debt_portfolio", "business_interest", "finalize", "completed"
]

// Customizable Area End