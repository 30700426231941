import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { person1, person2, person3 } from "./assets";
interface CarouselItem {
  name: string;
  occupation: string;
  comment: string;
  image: string;
};
export type BannerAttributes = {
  id: number;
  type: string;
  attributes: {
    id: number;
    banner_title: string;
    banner_title_arabic: string;
    banner_description: string;
    banner_description_arabic: string;
    created_at: string;
    updated_at: string;
    image: string;
    sub_text1:string;
    sub_text2:string;
  };
};
export type TestimonialAttributes = {
  id: number,
  type: string,
  attributes: {
    id: number,
    comment: string,
    username: string,
    designation: string,
    image: string,
  }
}

// Customizable Area End

export const configJSON = require("./config");
const utills = require('../../utilities/src/utills');

interface Response {
  data?:LandingPageData[],
  errors?: {[keyName:string]:unknown}[]
}

interface LandingPageData {
  id: string;
  type: string;
  attributes: {
    id: number;
    sequence: number;
    title: string;
    title_in_arabic: string;
    sub_title: string;
    sub_title_in_arabic: string;
    description: string;
    description_in_arabic: string;
    image: string;
    created_at: string;
    updated_at: string;
  };
}


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // loading: boolean;
  data: LandingPageData[];
  currentLanguage: 'en'|'ar';
  carouselItemCount: number;
  activeCarouselStep: number;
  carouselItemArray: CarouselItem[]
  bannerData: BannerAttributes;
  testimonialData: TestimonialAttributes[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataApiCallId: string = "";
  arrayResponse: LandingPageData[];
  getBannerCallID: string = "";
  getTestimonialCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];
    this.arrayResponse = [];
    this.state = {
      // Customizable Area Start
      data: [],
      currentLanguage: configJSON.EN,
      carouselItemCount: 3,
      activeCarouselStep: 0,
      carouselItemArray: [
        {
          name: "John Smith",
          occupation: "Financial Analyst",
          comment: "An incredible platform that revolutionized how I manage my debts. From listing to settlement, Jeddah House of Commerce ensured a seamless process every step of the way. Highly recommend!",
          image: person1
        },
        {
          name: "Sarah Johnson",
          occupation: "Debt Owner",
          comment: "Jeddah House of Commerce exceeded my expectations. Their platform is user-friendly, and their support team is incredibly helpful. A game-changer for debt management!",
          image: person2
        },
        {
          name: "David Rodriguez",
          occupation: "Debt Buyer",
          comment: "I've been using Jeddah House of Commerce for a few months now, and I'm impressed by their efficiency and professionalism. Their platform made it easy for me to sell my debts and connect with buyers.",
          image: person3
        },
        {
          name: "John Smith",
          occupation: "Financial Analyst",
          comment: "An incredible platform that revolutionized how I manage my debts. From listing to settlement, Jeddah House of Commerce ensured a seamless process every step of the way. Highly recommend!",
          image: person3
        },
        {
          name: "Sarah Johnson",
          occupation: "Debt Owner",
          comment: "Jeddah House of Commerce exceeded my expectations. Their platform is user-friendly, and their support team is incredibly helpful. A game-changer for debt management!",
          image: person1
        },
        {
          name: "David Rodriguez",
          occupation: "Debt Buyer",
          comment: "I've been using Jeddah House of Commerce for a few months now, and I'm impressed by their efficiency and professionalism. Their platform made it easy for me to sell my debts and connect with buyers.",
          image: person2
        }
      ],
      bannerData: {
        id: 0,
        type: "",
        attributes: {
          id: 0,
          banner_title: "",
          banner_title_arabic: "",
          banner_description: "",
          banner_description_arabic: "",
          created_at: "",
          updated_at: "",
          image: "",
          sub_text1:"",
          sub_text2:"",
        },
      },
      testimonialData: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    const language = await utills.getLanguageOrSetCurrentLanguage();
    this.setState({ currentLanguage: language })
    this.getResponse();
    window.addEventListener('resize', this.handleResize);
    this.setState({ carouselItemCount: this.getCarouselItemCount() });
    this.LandingPageGetBanner();
    this.LandingPageGetTestimonial();
  };

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  };

  handleResize = () => {
    this.setState({ carouselItemCount: this.getCarouselItemCount() });
  };

  handleResponse(responseJson: Response) {
    if (responseJson.errors) {
      if (responseJson.errors[0].token === "Invalid token") {
        const message: Message = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), "UserLogin");

        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      }
    } else {
      this.arrayResponse = responseJson.data!;
      if (this.arrayResponse) {
        this.setState({ data: this.arrayResponse });
      }
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getDataApiCallId) {
          this.handleResponse(responseJson);
        }
      }
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
        if (responseJson && !responseJson.errors && responseJson.data) {
          if (this.getBannerCallID === apiRequestCallId) {
            this.setState({ bannerData: responseJson.data[0] });
          } else if (this.getTestimonialCallID === apiRequestCallId) {
            this.setState({ testimonialData: responseJson.data });
          }
        }
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start

  getResponse() {
    const headers = {};

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.landingPageApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.messageId, requestMessage);
  }

  getCarouselItemCount = () => {
    const width = window.innerWidth;
    if (width < 640) {
      return 1;
    } else if (width < 1040) {
      return 2;
    }
    return 3;
  };

  handleCarouselNextItem = () => {
    const {carouselItemCount} = this.state;
    this.setState((prevState) => ({
      activeCarouselStep:
        (prevState.activeCarouselStep + 1) % Math.ceil(this.state.testimonialData.length / carouselItemCount)
    }));
  };

  handleCarouselPrevItem = () => {
    const {carouselItemCount} = this.state;
    this.setState((prevState) => ({
      activeCarouselStep:
        (prevState.activeCarouselStep - 1 + Math.ceil(this.state.testimonialData.length / carouselItemCount)) %
        Math.ceil(this.state.testimonialData.length / carouselItemCount)
    }));
  };

  LandingPageGetBanner = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBannerCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.landingBannerID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  LandingPageGetTestimonial = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTestimonialCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.landingTestimonialID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
